// Theme Colors
$color-white: #ffffff;
$color-black: #000;
$color-grey: #cbc9c9;
$color-grey-border: #b6b4b4;
$color-grey-allocation-border: #ccc8c8;
$color-text-regular: #333333;
$color-disabled: #f2f1f1;
$color-disabled-text: #929292;
$color-grey-light: #fafafa;
$color-grey-dark: #4f4f4f;
$color-dark-blue: #293749;
$color-red: #b62525;
$color-blue: #3a73d8;
$color-link-blue: #284162;
$color-link-visited: #7834bc;
$color-placeholder: #636161;
$color-nice-green: #ecf1e8;

$color-alert-error: #f1e7e6;
$color-alert-error-highlight: #d1080c;
$color-alert-warning: #f9f4d8;
$color-alert-warning-highlight: #d0b100;
$color-alert-success: #ecf1e8;
$color-alert-success-highlight: #54981e;
$color-alert-info: #effbff;
$color-alert-info-highlight: #1d9bc2;
$color-alert-danger: #fff5ed;
$color-alert-danger-highlight: #e08645;
$color-alert-warning-text: #903534;

$color-divider: #c4c4c4;
$color-table-header-odd: #3a73d8;
$color-table-header-even: #6599f6;
$color-table-body-odd: #f2f2f2;
$color-table-body-even: #fafafa;

$color-landing-page-background: rgba(58, 115, 216, 0.9);

:export {
  colorWhite: $color-white;
  colorBlack: $color-black;
  colorGrey: $color-grey;
  colorGreyBorder: $color-grey-border;
  colorGreyAllocationBorder: $color-grey-allocation-border;
  colorTextRegular: $color-text-regular;
  colorDisabled: $color-disabled;
  colorDisabledText: $color-disabled-text;
  colorGreyLight: $color-grey-light;
  colorGreyDark: $color-grey-dark;
  colorDarkBlue: $color-dark-blue;
  colorRed: $color-red;
  colorBlue: $color-blue;
  colorLinkBlue: $color-link-blue;
  colorLinkVisited: $color-link-visited;
  colorPlaceholder: $color-placeholder;
  colorNiceGreen: $color-nice-green;
  colorAlertError: $color-alert-error;
  colorAlertErrorHighlight: $color-alert-error-highlight;
  colorAlertWarning: $color-alert-warning;
  colorAlertWarningHighlight: $color-alert-warning-highlight;
  colorAlertSuccess: $color-alert-success;
  colorAlertSuccessHighlight: $color-alert-success-highlight;
  colorAlertInfo: $color-alert-info;
  colorAlertInfoHighlight: $color-alert-info-highlight;
  colorAlertDanger: $color-alert-danger;
  colorAlertDangerHighlight: $color-alert-danger-highlight;
  colorAlertWarningText: $color-alert-warning-text;
  colorDivider: $color-divider;
  colorTableHeaderOdd: $color-table-header-odd;
  colorTableHeaderEven: $color-table-header-even;
  colorTableBodyOdd: $color-table-body-odd;
  colorTableBodyEven: $color-table-body-even;
  colorLandingPageBackground: $color-landing-page-background;
}
