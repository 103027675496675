@import "../../../styles/base/breakpoints.scss";
@import "../../../styles/base/color.scss";
@import "../../../styles/base/variables.scss";

.navigation {
  background-color: $color-blue;

  &__mobileMenu {
    display: none;
  }

  &__container {
    margin: 0px 80px;
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style: none;
    &__button {
      background-color: transparent;
      color: $color-white;
      font-family: $title-font;
      border: none;
      border-bottom: 4px solid $color-blue !important;
      padding: 17px;
      font-size: 20.5px;
      border-radius: unset;
      height: 100%;

      &:hover {
        text-decoration: underline;
      }
      &:focus {
        outline-color: $color-white;
        outline-offset: 0;
        border-bottom: 4px solid $color-blue;
      }
      &:active {
        color: $color-white;
        background-color: transparent;
      }
    }
    &__button.selected {
      font-weight: 700;
      border-bottom: 4px solid $color-white !important;
    }
  }

  @media (max-width: 1200px) {
    &__mobileMenu {
      display: flex;
      padding: 4px 12px;
      border-bottom: 2px solid $color-white;

      &__button {
        display: inline;
      }

      &__menuIndicator {
        padding-left: 27px;
      }
    }
    &__container.invisible {
      display: none;
    }

    &__container {
      display: inline;
      position: absolute;
      margin: 0px;
      background-color: $color-blue;
      width: 100%;
      z-index: 2;
      &__button.selected {
        font-weight: 700;
        border-bottom: 0px !important;
        border-left: 4px solid $color-white !important;
      }
    }
  }
}
