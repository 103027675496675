@import "../../../styles/base/breakpoints.scss";
@import "../../../styles/base/color.scss";
@import "../../../styles/base/variables.scss";

.homeHero {
  background-color: $color-dark-blue;

  &__container {
    margin: 40px 80px;
  }

  @media (max-width: $media-m-max) {
    &__container {
      margin: 32px 16px;
    }
  }
}
