@import "../../styles/base/breakpoints.scss";
@import "../../styles/base/color.scss";
@import "../../styles/base/variables";

.header {
  display: flex;
  border-bottom: 4px solid $color-red;
  &__container {
    display: flex;
    flex: 1;
    padding: 24px 16px;
    justify-content: space-between;
    align-items: center;

    &__logo {
      max-width: 160px;
    }

    &__btns {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
      list-style: none;
    }

    &__button {
      border: none;
      background-color: transparent;
      text-decoration: underline;
      color: $color-link-blue;
      font-weight: 700;
      font-family: $title-font;
      cursor: pointer;
      font-size: 16px;
      padding-left: 16px;
      padding-right: 16px;
      &:active {
        color: $color-white;
        background-color: $color-blue;
      }
      &--mobile {
        text-decoration: underline;
      }
      &--desktop {
        text-transform: capitalize;
        text-decoration: underline;
        display: none;
      }
    }
  }

  @media (min-width: $media-m-min) {
    &__container {
      padding: 32px 80px;
      &__logo {
        max-width: 290px;
      }
      &__button {
        &--mobile {
          display: none;
        }
        &--desktop {
          display: block;
        }
      }
    }
  }
}
