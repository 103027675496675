@import "../../../styles/base/breakpoints.scss";
@import "../../../styles/base/color.scss";
@import "../../../styles/base/variables.scss";
@import "../home.scss";

.skip-link {
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  width: fit-content;
  background-color: $color-blue;
  color: $color-white;
  font-size: 16px;
  font-weight: $global-font-weight-demibold;

  &:focus {
    position: absolute;
    transform: translateY(0);
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    width: fit-content;
    background-color: $color-blue;
    color: $color-white;
    font-size: 16px;
    font-weight: $global-font-weight-demibold;
  }
}
.second-skip-link {
  position: absolute;
  transform: translateY(-200%);
  transition: transform 0.3s;
  margin-left: auto;
  margin-right: auto;
  top: 0;
  left: 0;
  right: 0;
  width: fit-content;
  background-color: $color-blue;
  color: $color-white;
  font-size: 16px;
  font-weight: $global-font-weight-demibold;

  &:focus {
    position: absolute;
    transform: translateY(0);
    margin-left: auto;
    margin-right: auto;
    top: 0;
    left: 0;
    right: 0;
    width: fit-content;
    background-color: $color-blue;
    color: $color-white;
    font-size: 16px;
    font-weight: $global-font-weight-demibold;
  }
}
