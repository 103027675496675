@import "../../../styles/base/breakpoints.scss";
@import "../../../styles/base/color.scss";
@import "../../../styles/base/variables.scss";

.landingPage {
  &__background {
    &__image {
      position: absolute;
      width: 100%;
      height: 480px;
      z-index: -2;
      @media (min-width: $media-t-min) {
        background-image: url("../../../assets/images/landing-page-header-background.png");
      }
      @media (max-width: $media-t-max) {
        height: 370px;
      }
    }
    &__overlay {
      position: absolute;
      width: 100%;
      height: 480px;
      z-index: -1;
      @media (min-width: $media-t-min) {
        background-color: $color-landing-page-background;
      }
      @media (max-width: $media-t-max) {
        height: 370px;
      }
    }
  }

  &__header {
    position: relative;
    max-height: 480px;
    @media (max-width: $media-t-max) {
      height: 130px;
    }

    &__imageContainer {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: -1;

      img {
        position: relative;
        transform: translate(-50%, 0);
        @media (max-width: $media-t-max) {
          width: 30%;
          height: auto;
        }
      }
    }

    &__text {
      padding-top: 168px;
      padding-bottom: 86px;
      padding-left: 142px;
      width: 600px;
      height: 450px;
    }
  }

  &__body {
    background-repeat: no-repeat;
    background-size: contain;

    @media (min-width: $media-t-min) {
      background-image: url("../../../assets/images/landing-page-body-background.svg");
    }

    @media (max-width: $media-t-max) {
      background-color: white;
    }

    &__container {
      table {
        margin-top: 27px;
        width: 100%;
        border-spacing: 6px;
        margin-left: -6px;
        margin-right: -6px;

        td {
          text-align: left;
          padding: 0 8px;
          @media (max-width: $media-t-max) {
            padding: 6px 8px;
          }
        }

        .overviewTableHeader {
          color: $color-white;
          background-color: $color-table-header-odd;
          font-size: 18px;
          width: 30%;
        }

        .overviewTableBody {
          color: $color-text-regular;
          background-color: $color-table-body-odd;
          font-size: 16px;
        }
      }
    }
  }
}
