@import "../../styles/base/breakpoints.scss";
@import "../../styles/base/color";

.footer {
  display: flex;
  border-top: 1px solid $color-grey;

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    flex: 1;
    padding: 40px 80px;
    &__text {
      display: flex;
      flex-direction: column;
      &__copyright {
        font-size: 14px;
        font-weight: 600;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-bottom: 16px;
      }

      &__linksContainer {
        font-size: 16px;
        display: flex;
        flex-direction: row;
        font-weight: 400;
        a {
          color: $color-grey-dark;
          text-decoration: none;
        }
        div {
          padding: 0px 15px;
        }
      }
    }
  }

  @media (max-width: $media-m-min) {
    &__container {
      padding: 26px 16px;
      &__text {
        &__copyright {
          max-width: 198px;
        }
        &__linksContainer {
          display: flex;
          flex-direction: column;
          margin-right: 10px;
          a {
            padding: 5px 0px;
          }
          div {
            display: none;
          }
        }
      }
      &__image {
        max-width: 120px;
        img {
          width: 100%;
        }
      }
    }
  }
}
